import React from 'react';
import ProfileCreation from '../ProfileCreation';


function Creation() {
  return (
    <>
        <ProfileCreation/>    
    </>
  );
}

export default Creation;