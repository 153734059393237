import React from 'react';
import LoginCard from "../LoginCard";

function Login() {
  return (
    <>
      <LoginCard/>
    </>
  );
}

export default Login;