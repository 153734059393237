import React from 'react';
import DetailedPosting from "../DetailedPosting";


function JobDetails() {
  return (
    <>
      <DetailedPosting />    
    </>
  );
}

export default JobDetails;