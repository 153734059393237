import React from 'react';
import CreateJobPosting from '../CreateJobPosting.js';


function PostAJob() {
  return (
    <>
        <CreateJobPosting/>    
    </>
  );
}

export default PostAJob;