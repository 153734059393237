import React from 'react';
import ProfilePage from "../ProfilePage";

function Profile() {
  return (
    <>
    <ProfilePage/>
    </>
  );
}

export default Profile;